import React, { useEffect, useState } from 'react';
import { Card } from 'antd';
import videosArray from '../../units/videos.json'


function Videocard() {
  // const serverUrl = "https://purple-dugong-850776.hostingersite.com/";
  // const [videoData, setVideoData] = useState([]);

  // const getData = async () => {
  //   try {
  //     const response = await fetch(`${serverUrl}api/upload/get-video`, {
  //       method: "GET",
  //     });

  //     if (!response.ok) {
  //       alert("Please Try Again");
  //       return;
  //     }

  //     const data = await response.json();
  //     const allVideos = data.videos;
  //     setVideoData(allVideos);
  //   } catch (error) {
  //     console.error(error);
  //     alert("An error occurred. Please try again.");
  //   }
  // };

  // useEffect(() => {
  //   getData();
  // }, []);

  return (
    <div className="videocard">
      <div className="container">
        <h2 className="gradient-text">Popular Videos</h2>
        <div className="row">
          {[...videosArray].reverse().slice(0, 8).map((video, index) => {
            return (
              <div key={index} className="col-lg-3 col-md-6 col-sm-6 spacediv">
                <Card>
                  <iframe
                    width="100%"
                    height="315"
                    src={`https://www.youtube.com/embed/${video.videoLink}?si=4Q3JGf8knKIT1c6Y`}
                    title={`YouTube video player ${index}`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  ></iframe>
                </Card>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  );
}

export default Videocard;
