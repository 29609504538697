import React, { useEffect, useState } from 'react';
import {
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UploadOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Layout, Menu, Button, theme } from 'antd';
import Video from './Video';
import Profile from './Profile';
import FristPage from './FristPage';
import { useNavigate } from 'react-router-dom';
import { getValues, deleteValues } from './indexedDB';

const { Header, Sider, Content } = Layout;

function MainContainer() {
  const serverUrl = "https://purple-dugong-850776.hostingersite.com/";
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false);
  const [selectedKey, setSelectedKey] = useState('dashboard');
  const [jwtIDB, setJwtIDB] = useState('');
  const [idIDB, setIdIDB] = useState('');

  const fetchIDBValues = async () => {
    try {
      const values = await getValues();
      setJwtIDB(values.token || '');
      setIdIDB(values.userId || '');

      if (!values.token || !values.userId) {
        navigate('/admin/login');
      }
    } catch (error) {
      console.error('Error fetching values:', error);
      navigate('/admin/login');
    }
  };


  const getData = async (id) => {
    try {
      const response = await fetch(`${serverUrl}api/auth/get-user/${id}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${jwtIDB}`,
        },
      });

      if (response.ok) {
        const data = await response.json();

        if (data.users.jwt !== jwtIDB) {
          navigate('/admin/login');
        }
      } else {
        navigate('/admin/login');
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
      navigate('/admin/login');
    }
  };

  useEffect(() => {
    fetchIDBValues();
  }, []);

  useEffect(() => {
    if (idIDB && jwtIDB) {
      getData(idIDB);
    }
  }, [idIDB, jwtIDB]);

  const handleMenuClick = (e) => {
    setSelectedKey(e.key);
  };

  const logoutFun = async () => {
    try {
      await deleteValues();
      setJwtIDB('');
      setIdIDB('');
      navigate('/admin/login');
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const renderContent = () => {
    switch (selectedKey) {
      case 'dashboard':
        return <FristPage />;
      case 'video':
        return <Video />;
      case 'profile':
        return <Profile />;
      default:
        return null;
    }
  };

  return (
    <div className='sidebarmenu'>
      <Layout style={{ height: '100%' }}>
        <Sider trigger={null} collapsible collapsed={collapsed} style={{ height: '100%' }}>
          <div className='demo-logo-vertical' />
          <Menu
            theme='dark'
            mode='inline'
            selectedKeys={[selectedKey]}
            onClick={handleMenuClick}
          >
            <Menu.Item key='dashboard' icon={<UserOutlined />}>
              DASHBOARD
            </Menu.Item>
            <Menu.Item key='video' icon={<UploadOutlined />}>
              VIDEO
            </Menu.Item>
            <Menu.Item key='profile' icon={<UserOutlined />}>
              PROFILE
            </Menu.Item>
            <Menu.Item key='register' icon={<UserOutlined />} onClick={() => navigate('/admin/register')}>
              REGISTER
            </Menu.Item>
            <Menu.Item key='logout' icon={<LogoutOutlined />} onClick={logoutFun}>
              Logout
            </Menu.Item>
          </Menu>
        </Sider>

        <Layout style={{ height: '100%' }}>
          <Header style={{ padding: '0px 30px', background: colorBgContainer }}>
            <Button
              type='text'
              icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={() => setCollapsed(!collapsed)}
              style={{
                fontSize: '16px',
                width: 64,
                height: 64,
              }}
            />
          </Header>
          <Content
            style={{
              margin: '24px 16px',
              padding: 24,
              minHeight: 'auto',
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
              overflowY: 'scroll',
            }}
          >
            {renderContent()}
          </Content>
        </Layout>
      </Layout>
    </div>
  );
}

export default MainContainer;
