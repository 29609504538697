import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getValues } from './indexedDB';

function SignIn() {
  const serverUrl = "https://purple-dugong-850776.hostingersite.com/";
  const navigate = useNavigate();
  const [user, setUser] = useState({
    name: "",
    email: "",
    password: "",
  });

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const values = await getValues();
        const token = values.token;
        if (!token) {
          navigate("/admin/login");
        }
      } catch (error) {
        console.error("Error checking authentication:", error);
        navigate("/admin/login");
      }
    };

    checkAuth();
  }, [navigate]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const handleData = async () => {
    try {
      const response = await fetch(`${serverUrl}api/auth/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(user),
      });

      const data = await response.json();

      if (response.ok) {
        alert("Hey, You Are Registered Here");
        setUser({
          name: "",
          email: "",
          password: "",
        });
        navigate("/admin");
      } else if (response.status === 400) {
        alert("Email Already Exists");
      } else {
        alert("Please Try again");
      }
    } catch (error) {
      alert("An error occurred. Please try again.");
      console.error("Error during registration:", error);
    }
  };

  return (
    <div>
      <div className='signupform'>
        <input type="radio" id="registerForm" name="formToggle" checked readOnly />

        <div className="wrapper" id="registerFormContent">
          <h1>Register</h1>

          <div className="input-box">
            <input
              type="text"
              name="name"
              placeholder="Name"
              value={user.name}
              required
              onChange={handleChange}
            />
          </div>

          <div className="input-box">
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={user.email}
              required
              onChange={handleChange}
            />
          </div>

          <div className="input-box">
            <input
              type="password"
              name="password"
              placeholder="Password"
              value={user.password}
              required
              onChange={handleChange}
            />
          </div>

          <div className="checkbox1">
            {/* <label><input type="checkbox" required />I agree to terms & conditions</label> */}
          </div>

          <button className="btn" onClick={handleData}>Register</button>

          <div className="link">
            <p>Go Back =&gt; <Link to='/admin'>Admin</Link></p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignIn;
